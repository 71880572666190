import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import MUIInputAdornment from '@mui/material/InputAdornment';
import MUITextField from './styles';

export const InputAdornment = ({ children, ...props }) => (
  <MUIInputAdornment {...props}>{children}</MUIInputAdornment>
);

const TextField = (props) => {
  const [focused, setFocus] = useState(false);

  const {
    id,
    type = 'text',
    format,
    placeholderText,
    nameType,
    values,
    value,
    formValid = {},
    formErrors,
    className,
    handleFocus,
    handleBlur,
    handleChange,
    handleKeyPress,
    shadow,
    showOnlyFormError,
    suffix,
    variant = 'filled',
    InputProps,
    inputProps,
    InputLabelProps,
    ...otherProps
  } = props;

  const [labelId] = useState(`${id}-${Math.floor(Math.random() * 100000000)}`);

  const onHandleFocus = (event) => {
    setFocus(true);
    if (handleFocus !== undefined) handleFocus(id, event);
  };

  const onHandleBlur = (event) => {
    setFocus(false);
    if (handleBlur !== undefined) handleBlur(id, event.target.value);
  };

  const onHandleChange = (event) => {
    if (handleChange !== undefined) handleChange(id, event.target.value);
  };

  const onHandleKeyPress = (event) => {
    if (handleKeyPress !== undefined) handleKeyPress(event);
  };

  const currHelperText = !showOnlyFormError
    ? focused
      ? nameType
      : formValid?.[id]
      ? formErrors?.[id]
      : nameType
    : formValid?.[id] === -1 && formErrors
    ? formErrors?.[id]
    : null;

  const label = currHelperText || nameType;

  const textfieldProps = {
    id: labelId,
    name: id,
    className,
    error: formValid?.[id] === -1,
    label,
    hiddenLabel: !label,
    value: value || (values && values[id]) || '',
    placeholder: placeholderText,
    variant,
    suffix,
    shadow: (!!shadow).toString(),
    onBlur: onHandleBlur,
    onChange: onHandleChange,
    onFocus: onHandleFocus,
    onKeyPress: onHandleKeyPress,
    ...otherProps,
    inputProps: {
      'data-testid': `textfield-${id}`,
      'data-optimizely': `textfield-input-${id}`,
      className: 'text-field-input',
      ...inputProps,
    },
    InputLabelProps: {
      className: 'text-field-label',
      'data-optimizely': `textfield-label-${id}`,
      ...InputLabelProps,
    },
    InputProps: {
      ...(type === 'currency' && {
        startAdornment: (
          <InputAdornment position="start" sx={{ fontFamily: 'var(--font-family)' }}>
            $
          </InputAdornment>
        ),
      }),
      disableUnderline: true,
      ...InputProps,
    },
  };

  return type === 'telephone' ? (
    <NumberFormat
      type="tel"
      format={format || '(###) ###-####'}
      mask=""
      customInput={MUITextField}
      {...textfieldProps}
    />
  ) : type === 'currency' || type === 'number' ? (
    <NumberFormat
      isNumericString
      customInput={MUITextField}
      {...textfieldProps}
      value={value === 0 || !!value ? value : values?.[id] || values?.[id] === 0 ? values[id] : ''}
    />
  ) : (
    <MUITextField {...textfieldProps} type={type} />
  );
};

export default TextField;
