import React, { useEffect, useState, useRef } from 'react';
import { Col, Row } from 'components/Core/Grid';
import { STIcon } from 'components/Brand/Icons/STIcon';
import Slider from 'react-slick';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import useIndustries from 'hooks/useIndustries';
import Button from 'components/Core/Button';
import FormLabelWithField from 'components/Core/FormLabelWithField';
import Progress from 'components/Core/Progress';
import WebsiteImage from 'components/WebsiteImage';
import HyperLink from 'components/Core/HyperLink';
import TextField from 'components/Core/TextField';
import { FormRedirect, getFormRedirectValues } from 'components/Form/FormRedirect';
import useNormalDQFlow from 'hooks/useNormalDQFlow';
import useMarketoFormData from 'hooks/useMarketoFormData';
import { mapFormFieldValues, mapFormFieldValue } from 'utils/marketo';
import isNil from 'utils/isNil';
import {
  clearCookies,
  getSetCookies,
  getCookieSanitized,
  setCookieSanitized,
} from 'utils/cookieUtility';
import snackbar from 'utils/snackbar';
import { init, saveForm } from 'services/marketoService';
import Text from 'components/Core/Text';
import { formSubmittedEvent, potentialFraudEvent } from 'services/dataLayerService';
import handleNavigate from 'utils/handleNavigate';
import { truthyFilter } from 'utils/text';
import { WithTaxonomyContext } from 'utils/TaxonomyContext';
import { FormContainer, FormTitle, FormStep } from './styles';
import DynamicFormField from './DynamicFormField';
import { initSteps, evalVisibilityRule } from './utils/helperFunctions';

const validFieldTypes = [
  'text',
  'textArea',
  'telephone',
  'email',
  'select',
  'radioButtons',
  'checkbox',
  'checkboxes',
  'number',
];

export const filterFields = (ar, types = validFieldTypes) =>
  ar?.filter((field) => types.includes(field?.dataType));

const ButtonText = ({ step, steps, buttonLabel, NextLabel }) => {
  if (step + 1 < steps.length) {
    return <span>{NextLabel}</span>;
  }

  return (
    <>
      {buttonLabel === 'Download' && <STIcon type="icon-download" />}
      {buttonLabel}
    </>
  );
};

const DynamicMarketoForm = ({
  allowMultiSubmissions,
  dataPosition,
  width,
  height,
  backgroundColor,
  title = '',
  titleColor,
  titleImage,
  titleSize,
  titleBackgroundColor,
  buttonLabel,
  buttonType = 'primary',
  footer,
  formType,
  handleFocus,
  shadow,
  marketoFormId,
  marketoFormData,
  redirectUrl,
  onSubmit,
  onSuccess,
  formName,
  showPrivacy = true,
  termsText,
  showReveal,
  notificationStyles,
  notificationMessage,
  notificationSnack,
  shouldRedirect = true,
  formBuilderData,
  hideProgressBar,
  NextLabel,
  onSetValues = () => {},
  stepChange = () => {},
  submitOnEachStep,
  useDataCy,
  autoAdvance = false,
  passedValues = {},
  stepPolicy = {},
  useCookieValues = null,
  submitStep = false,
  clearOnBack = false,
  customIcp,
  useEnhancedSelect = false,
  className = '',
  onFormSubmissionInlineMarketingForm,
  industryInclusionList,
  industryExclusionList,
  isEmailCapture,
  ...otherProps
}) => {
  const { allIndustriesList } = useIndustries(industryInclusionList, industryExclusionList);
  const prefilledIndustry = allIndustriesList.find(
    (x) =>
      x.value.toLowerCase() === otherProps.taxonomy?.industries?.[0].toLowerCase() ||
      x.label.toLowerCase() === otherProps.taxonomy?.industries?.[0].toLowerCase(),
  );
  const marketDetails = useMarketoFormData(marketoFormId);
  const marketoFormDetails = marketoFormId && marketDetails;

  const [formErrors, setFormErrors] = useState({});
  const [step, setStep] = useState(stepPolicy.startStep || 0);
  const [steps, setSteps] = useState(initSteps(marketoFormDetails));
  const allFormFields = filterFields(steps?.flatMap((x) => x.fields));
  const [honeyPot, setHoneyPot] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSlickMoving, setSlickMoving] = useState(false);
  const [formId] = useState(`form-${Math.floor(Math.random() * 100000000)}`);
  const slider = useRef(null);
  let dataLayerValues = {};

  const checkUseCookieValues = (vals) => {
    if (!useCookieValues) {
      return vals;
    }
    Object.keys(vals)
      .filter((id) => !useCookieValues.includes(id))
      .forEach((id) => {
        vals[id] = '';
      });
    return vals;
  };

  const loadDefaultValues = (cookies) => {
    const defaultMarketoValues = {};
    const cookieValues = getSetCookies(cookies);

    allFormFields.forEach((field) => {
      if (!defaultMarketoValues[field.id] && field.defaultValue) {
        switch (field.dataType) {
          case 'number':
            defaultMarketoValues[field.id] = parseInt(field.defaultValue);
            break;
          case 'select':
            defaultMarketoValues[field.id] =
              field.defaultValue !== '[""]' ? field.defaultValue : '';
            break;
          default:
            defaultMarketoValues[field.id] = field.defaultValue;
            break;
        }
      }
      if (getCookieSanitized(field.id)) {
        cookieValues[field.id] =
          !useCookieValues || useCookieValues.includes(field.id)
            ? getCookieSanitized(field.id)
            : '';
        if (field?.fieldMetaData?.multiSelect && cookieValues[field.id]) {
          cookieValues[field.id] = cookieValues[field.id].split(',');
        }
      }
    });

    return { ...defaultMarketoValues, ...checkUseCookieValues(cookies), ...cookieValues };
  };

  const [values, setValues] = useState({});
  const normalDQFlow = useNormalDQFlow();
  const DQFlow = customIcp || normalDQFlow;

  const sliderSettings = {
    dots: false,
    arrows: false,
    infinite: false,
    draggable: false,
    touchMove: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    accessibility: false,
    cssEase: 'cubic-bezier(0.55, 0.085, 0.68, 0.53)',
    beforeChange: () => {
      setTimeout(() => {
        setSlickMoving(false);
      }, 600);
    },
  };

  const hasImage = !isNil(titleImage);

  const isProfiling = marketoFormDetails?.fields?.some((item) => item.id === 'Profiling');

  buttonLabel = buttonLabel || marketoFormDetails?.buttonLabel || 'Submit';

  const getVisibleFields = (fields) =>
    fields &&
    filterFields(fields).filter(
      (field) =>
        (!field.visibilityRules ||
          field.visibilityRules.ruleType === 'alwaysShow' ||
          field.visibilityRules.rules.every((rule) =>
            evalVisibilityRule(rule, field.visibilityRules.ruleType, values),
          )) &&
        !(useEnhancedSelect && field.id === 'industryTestVerticals'),
    );

  useEffect(() => {
    stepChange(values, step);
  }, [step]);

  useEffect(() => {
    if (slider.current && stepPolicy.startStep) {
      slider.current.slickGoTo(stepPolicy.startStep, true);
    }
  }, [slider.current]);

  useEffect(() => {
    init(marketoFormId);
    setValues(
      loadDefaultValues({
        FirstName: getCookieSanitized('firstName'),
        LastName: getCookieSanitized('lastName'),
        Company: getCookieSanitized('company'),
        Email: getCookieSanitized('email'),
        Phone: getCookieSanitized('mobilePhone'),
        employee: getCookieSanitized('employee'),
        Role__c: getCookieSanitized('contactRole'),
        Primary_Industry__c: prefilledIndustry?.value || getCookieSanitized('industry'),
        mobilePhone: getCookieSanitized('mobilePhone'),
        utm_campaign: getCookieSanitized('utm_campaign'),
        utm_content: getCookieSanitized('utm_content'),
        utm_medium: getCookieSanitized('utm_medium'),
        utm_source: getCookieSanitized('utm_source'),
        utm_term: getCookieSanitized('utm_term'),
        utm_promo: getCookieSanitized('utm_promo'),
        cid: getCookieSanitized('cid'),
      }),
    );
    setSteps(initSteps(marketoFormDetails));
  }, []);

  const validateField = (id, value) => {
    const field = allFormFields.find((x) => x.id === id);

    if (!field?.required) {
      return '';
    }

    let fieldName = field?.label.replace('Enter ', '');
    fieldName = fieldName.charAt(0).toUpperCase() + fieldName.slice(1);
    if ((typeof value === 'string' && value.trim() === '') || isNil(value)) {
      return `${fieldName} is required`;
    }

    switch (field?.dataType) {
      case 'telephone':
        return value.length !== 14 ? `${fieldName} is invalid` : '';
      case 'email':
        return value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? '' : `${fieldName} is invalid`;
      default:
        return '';
    }
  };

  const checkFieldValidate = (id, value) => {
    const fieldValidationErrors = { ...formErrors };
    fieldValidationErrors[id] = validateField(id, value);
    setFormErrors(fieldValidationErrors);
  };

  const getData = () => ({
    ...values,
    step: step + 1,
    marketoFormId,
    ...((values.areaOfFocus || values.typeOfBusiness) && {
      businessFocus: `${values.areaOfFocus || ''}${
        values.typeOfBusiness ? ` ${values.typeOfBusiness}` : ''
      }`,
    }),
  });

  const submit = async () => {
    if (!allowMultiSubmissions) {
      setIsSubmitted(true);
    }

    if (honeyPot) {
      potentialFraudEvent(values);
      return;
    }

    const data = getData();
    if (submitStep) {
      data.step = submitStep;
    }

    if (onSubmit) {
      onSubmit(data);
    }

    clearCookies(values);

    allFormFields.forEach((field) => {
      setCookieSanitized(field.id, values[field.id]);
    });

    setCookieSanitized('firstName', values.FirstName);
    setCookieSanitized('lastName', values.LastName);
    setCookieSanitized('company', values.Company);
    setCookieSanitized('email', values.email || values.Email);
    setCookieSanitized('mobilePhone', values.Phone);
    setCookieSanitized('contactRole', values.Role__c);
    setCookieSanitized('industry', values.Primary_Industry__c);

    let response;

    try {
      response = await saveForm(data);
    } catch (e) {
      return;
    }

    const redirect = redirectUrl || response?.data?.followUpUrl;

    if (isProfiling) {
      setCookieSanitized(
        `marketo_form_${marketoFormId}_submit`,
        JSON.stringify({ event: 'form submitted', dataLayerValues }),
      );
    }

    if (onSuccess) {
      onSuccess({ setValues, values: data, redirectUrl: redirect });
    }

    if (notificationSnack && notificationMessage) {
      snackbar(notificationMessage);
    }

    if (customIcp) {
      FormRedirect(DQFlow, { ...data, ...getFormRedirectValues(data) });
    }

    if (shouldRedirect) {
      if (redirect) {
        handleNavigate(redirect);
      }
    }
  };

  const getFormPercent = () => {
    const validCount = allFormFields.reduce((cur, field) => {
      const { id } = field;
      return cur + (validateField(id, values[id]).length > 0 ? 0 : 1);
    }, 0);

    return (validCount / allFormFields.length) * 100;
  };

  const prevStep = () => {
    const newStep = step - 1;
    const stepFields = [
      ...getVisibleFields(steps[step].fields),
      ...getVisibleFields(steps[newStep].fields),
    ];
    const tmpValues = { ...values };
    const tmpErrors = { ...formErrors };
    stepFields.forEach((field) => {
      if (clearOnBack && !useCookieValues?.includes(field.id)) {
        tmpValues[field.id] = '';
      }
      tmpErrors[field.id] = '';
    });
    setValues({ ...tmpValues });
    setFormErrors({ ...tmpErrors });

    if ((stepPolicy.startStep !== undefined && newStep < stepPolicy.startStep) || newStep < 0) {
      return;
    }

    if (isSlickMoving) {
      return;
    }
    setSlickMoving(true);
    setStep(newStep);
    if (newStep === 0 && !autoAdvance) {
      slider.current?.slickGoTo(newStep, true);
    } else {
      slider.current?.slickPrev();
    }
  };

  const nextStep = async (newValues = values) => {
    const newStep = step + 1;

    const fieldValidationErrors = { ...formErrors };

    const invalidCount = getVisibleFields(steps[step].fields).reduce((count, fields) => {
      fieldValidationErrors[fields.id] = validateField(fields.id, newValues[fields.id]);
      return count + (fieldValidationErrors[fields.id] ? 1 : 0);
    }, 0);

    setFormErrors(fieldValidationErrors);

    dataLayerValues = {
      form_type: formType || 'Dynamic Marketo Form',
      form_step: step + 1,
      ...(prefilledIndustry?.value && { 'prefilled-search': prefilledIndustry?.value }),
      ...(dataPosition && { 'data-position': dataPosition }),
      ...mapFormFieldValues(truthyFilter(newValues)),
      ...((newStep >= steps.length || submitOnEachStep) &&
        otherProps.formSubmittedDataLayerProps?.(newValues)),
    };

    if (invalidCount) {
      return;
    }

    if (
      step === 0 &&
      newValues.Primary_Industry__c === 'Other' &&
      newValues.industryTestVerticals?.length === 0
    ) {
      return;
    }

    if (stepPolicy.stopStep !== undefined && newStep > stepPolicy.stopStep) {
      return;
    }

    formSubmittedEvent(dataLayerValues);

    if (newStep < steps.length) {
      if (isSlickMoving) {
        return;
      }
      setSlickMoving(true);
      setStep(newStep);
      if (step === 0 && !autoAdvance) {
        slider.current?.slickGoTo(newStep, true);
      } else {
        slider.current?.slickNext();
      }
      if (submitOnEachStep) {
        await saveForm(getData());
      }
    } else {
      await submit();
    }
  };

  useEffect(() => {
    if (
      passedValues?.current &&
      Object.keys(passedValues?.current).length > 0 &&
      JSON.stringify({ ...values, ...passedValues.current }) !== JSON.stringify(values)
    ) {
      const tmpValues = { ...values, ...passedValues.current };
      delete tmpValues.gotoNext;
      setValues({ ...tmpValues });
      onSetValues(tmpValues, step);
      if (passedValues.current.gotoNext) {
        nextStep({ ...tmpValues });
      }
      passedValues.current = {};
    }
  }, [passedValues.current]);

  const onHandleFocus = (id, event) => {
    if (handleFocus) {
      handleFocus(id, event);
    }
  };

  const onHandleBlur = (id, newValue) => {
    checkFieldValidate(id, newValue);
    onSetValues(values, step);
  };

  const onHandleChange = (id, newValue, type, skipValidation) => {
    const newValues = { ...values };
    newValues[id] = newValue;
    if (Array.isArray(newValues[id]) && newValues[id].length === 0) {
      newValues[id] = null;
    }
    setValues(newValues);
    if (!skipValidation) {
      checkFieldValidate(id, newValue);
    }
    if (type !== 'TextField') {
      onSetValues(newValues, step);
    }
    showReveal?.(true);

    if (autoAdvance && steps.length > 1) {
      const stepFields = getVisibleFields(steps[step].fields);
      const stepValid = stepFields.every((field) => !validateField(field.id, newValues[field.id]));
      if (stepValid) {
        if (id !== 'Primary_Industry__c' || newValue !== 'Other') {
          nextStep(newValues);
        }
      }
    }
  };

  const TermsText = () => {
    if (termsText?.raw) {
      return renderRichText(termsText);
    }
    return termsText;
  };

  const getDisabledStyles = () =>
    getVisibleFields(steps[step].fields).reduce((count, fields) => {
      return count + (validateField(fields.id, values[fields.id]) ? 1 : 0);
    }, 0) > 0
      ? ''
      : 'enable';

  return (
    <FormContainer
      {...(useDataCy && { 'data-cy': 'dynamicFormContainer' })}
      data-optimizely="form-container"
      className={`dynamic-form--container ${className}`}
      width={width}
      height={height}
      backgroundColor={backgroundColor}
      titleColor={titleColor}
      shadow={shadow}
      {...otherProps}
    >
      <Row className={`signup-form--header signup-form--step-${step}`}>
        <Col flex="auto" xs={24} className="signup-form--header--inner-container">
          {step > (stepPolicy.startStep || 0) && (
            <button
              id="back-button"
              data-position="Form - CTA"
              type="button"
              onClick={prevStep}
              className="slick-prev"
              data-cy={useDataCy ? 'back arrow' : undefined}
            >
              Previous
            </button>
          )}
          {hasImage ? (
            <FormTitle
              className={`signup-form--title`}
              data-optimizely="form-title"
              titleColor={titleColor}
              titleSize={titleSize}
            >
              <WebsiteImage
                className="signup-form--title-image"
                image={titleImage}
                alt={title}
                width="100%"
              />
            </FormTitle>
          ) : (
            <>
              <FormTitle
                className={`signup-form--title signup-form--title-0 ${
                  step === 0 ? 'block' : 'hidden'
                }`}
                data-optimizely="form-title"
                titleColor={titleColor}
                titleSize={titleSize}
              >
                {title}
              </FormTitle>
              <FormTitle
                className={`signup-form--title signup-form--title-1 ${
                  step > 0 ? 'block' : 'hidden'
                }`}
                data-optimizely="form-title"
                titleColor={titleColor}
                titleSize={titleSize}
              >
                Thanks! <br /> Tell us a little more about yourself
              </FormTitle>
            </>
          )}
        </Col>
      </Row>
      <form
        id={formId}
        data-position={dataPosition || formName}
        data-optimizely="form-element"
        className="signup-form--form"
        onSubmit={(e) => e.preventDefault()}
        noValidate="novalidate"
      >
        <Slider ref={slider} {...sliderSettings}>
          {steps?.map((curStep, index) => (
            <FormStep
              key={index}
              className={`data-layer-form form-step-${index}`}
              data-cy={useDataCy ? `container step ${step + 1}` : undefined}
              data-optimizely={`form-step-${step + 1}`}
            >
              {getVisibleFields(curStep.fields)?.map((field, index1) => (
                <DynamicFormField
                  key={index1}
                  formControlProps={{
                    className: `form-field ${field.id}`,
                    'data-optimizely': `form-item-${field.id}`,
                  }}
                  field={field}
                  value={values[field.id]}
                  values={values}
                  formErrors={formErrors}
                  onHandleBlur={onHandleBlur}
                  onHandleChange={onHandleChange}
                  onHandleFocus={onHandleFocus}
                  dataCy={useDataCy ? mapFormFieldValue(field.id) : undefined}
                  useEnhancedSelect={useEnhancedSelect}
                  isModalForm={otherProps.isModalForm}
                  $prefillValue={prefilledIndustry?.value}
                  $industryInclusionList={industryInclusionList}
                  $industryExclusionList={industryExclusionList}
                  $allIndustriesList={allIndustriesList}
                />
              ))}

              <FormLabelWithField
                formControlProps={{ className: 'form-field signup-form--hp123-item' }}
              >
                <TextField
                  id="hp123"
                  type="text"
                  nameType="Hp123"
                  placeholderText="Hp123 Number"
                  handleChange={() => setHoneyPot(true)}
                />
              </FormLabelWithField>
              <div className="form-field">
                <Button
                  className={`signup-form--submit-button ${
                    otherProps.disableButtonUntilValid && getDisabledStyles()
                  }`}
                  type={buttonLabel === 'Subscribe' ? 'secondary' : buttonType}
                  width="100%"
                  onClick={() => nextStep()}
                  disabled={allowMultiSubmissions ? false : isSubmitted}
                  shadow={false}
                  data-optimizely={`submit-button-${step + 1}`}
                  dataCy={
                    useDataCy
                      ? steps.length === 1
                        ? 'submit'
                        : step + 1 === steps.length
                        ? 'final submit'
                        : `step ${step + 1} submit`
                      : undefined
                  }
                >
                  <ButtonText
                    step={step}
                    steps={steps}
                    buttonLabel={buttonLabel}
                    NextLabel={NextLabel}
                  />
                </Button>
              </div>
            </FormStep>
          ))}
        </Slider>
        {isSubmitted && notificationMessage && !notificationSnack && (
          <Text
            type="p"
            size="normal"
            className={notificationStyles || 'section-hero--notification'}
            color="#00bb22"
          >
            {notificationMessage}
          </Text>
        )}
        {!hideProgressBar && allFormFields.length > 1 && <Progress value={getFormPercent()} />}
        {showPrivacy && (
          <div className="signup-form--footer" {...(useDataCy && { 'data-cy': 'footerTextDiv' })}>
            <TermsText />
          </div>
        )}
      </form>
    </FormContainer>
  );
};

DynamicMarketoForm.defaultProps = {
  backgroundColor: 'var(--white)',
  titleColor: 'var(--darkest)',
  title: 'Let’s get started.',
  buttonType: 'primary',
  footer: '',
  width: '100%',
  NextLabel: 'Next',
  shadow: true,
  termsText: (
    <p>
      By submitting this form, I confirm that I have read and agree to the{' '}
      <HyperLink href="/privacy-policy">Privacy Statement.</HyperLink>
    </p>
  ),
  notificationSnack: false,
};

export default WithTaxonomyContext(DynamicMarketoForm);
