import React, { useState, useEffect } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import InputAdornment from '@mui/material/InputAdornment';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Select from 'components/Core/Select';
import RadioGroup from 'components/Core/RadioGroup';
import { FormContainer } from 'components/Form/DynamicMarketoForm/styles';
import { Col, Row } from 'components/Core/Grid';
import { OptionsContainer, Title, OptionsModal, SelectContainer } from './styled';

export const toggleClass = (container, add = true, className = 'options-open') => {
  if (container?.current) {
    const formContainer = container.current.closest(
      '.dynamic-form--container, .demo-form--container',
    );

    if (formContainer) {
      if (!add) {
        formContainer.classList.remove(className);
      } else {
        formContainer.classList.add(className);
      }
    }
  }
};

const Options = React.forwardRef((props, ref) => {
  const {
    visible,
    dataCy,
    label,
    options,
    inlineOptions,
    handleChange,
    prevClick,
    subtitle,
    footerText,
    id,
  } = props;
  return (
    <OptionsContainer
      className="options-container"
      ref={ref}
      visible={visible}
      isModalForm={options}
      {...(dataCy && { 'data-cy': `${dataCy}OptionsContainer` })}
    >
      <Row>
        <Col xs={24}>
          {inlineOptions && (
            <button
              data-position="Form - CTA"
              type="button"
              className="slick-prev"
              onClick={prevClick}
              {...(dataCy && { 'data-cy': `${dataCy}BackButton` })}
            >
              Previous
            </button>
          )}
          <Title>{label}</Title>
        </Col>
      </Row>
      {subtitle && <p className="font-p subtitle">{subtitle}</p>}
      <RadioGroup id={id} options={options} formValid handleFocus={handleChange} dataCy={dataCy} />
      {footerText && <p className="font-p footer">{footerText}</p>}
    </OptionsContainer>
  );
});

const SelectEnhanced = ({
  autoExpandOptions = true,
  className,
  currentOptions,
  dataCy,
  defaultValue,
  formErrors,
  formValid = {},
  HeaderComponent,
  id,
  isModalForm: inlineOptions,
  label,
  nameType,
  onPrevClick,
  options = [],
  placeholder,
  refreshingFocus,
  showOptions = false,
  subtitle,
  footerText,
  value = '',
  values,
  ...otherProps
}) => {
  const [currentValue, setCurrentValue] = useState(value);
  const [optionsVisible, setOptionsVisible] = useState(false);
  const [slideHeight, setSlideHeight] = useState();
  const [selectKey, setSelectKey] = useState(1);
  const [isEmpty, setEmpty] = useState(true);
  const container = React.createRef();

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  useEffect(() => {
    setEmpty(!currentValue);
  }, [currentValue]);

  const close = () => {
    setOptionsVisible(false);
    toggleClass(container, false);
  };

  const removeFocus = () => {
    if (isEmpty) {
      // When no option is selected, this removes focus
      setSelectKey((prevKey) => prevKey + 1);
    }
  };

  const prevClick = () => {
    close();
    onPrevClick?.();
    removeFocus();
  };

  useEffect(() => {
    removeFocus();
  }, [refreshingFocus]);

  const handleChange = (_id, newValue) => {
    close();
    setCurrentValue(newValue);
    otherProps.handleChange?.(_id, newValue);
  };

  const handleClick = () => {
    setOptionsVisible(true);
    toggleClass(container, true);
  };

  useEffect(() => {
    if (dataCy) {
      const inputs = [...document?.querySelectorAll(`.${id}Select`)];
      inputs?.map((input) =>
        input?.getElementsByTagName('input')?.[0]?.setAttribute('data-cy', `${dataCy}Input`),
      );
    }
  });

  return (
    <>
      <SelectContainer className={className}>
        <Select
          name={id}
          data-testid={id && `select-${id}`}
          data-cy={dataCy}
          selectKey={selectKey}
          placeholder={placeholder}
          id={id}
          options={currentOptions?.length > 0 ? currentOptions : options}
          defaultValue={defaultValue}
          value={currentValue}
          formValid={formValid}
          className={`${id}Select`}
          SelectProps={{
            onOpen: handleClick,
            open: false,
            IconComponent: value || values?.[id] ? EditIcon : ArrowForwardIosIcon,
          }}
          {...(HeaderComponent && {
            InputProps: {
              startAdornment: <InputAdornment position="start">{HeaderComponent}</InputAdornment>,
            },
          })}
        />
      </SelectContainer>
      {inlineOptions ? (
        <Options
          ref={container}
          visible={optionsVisible || showOptions}
          options={options}
          inlineOptions={inlineOptions}
          dataCy={dataCy}
          prevClick={prevClick}
          label={label}
          id={id}
          subtitle={subtitle}
          footerText={footerText}
          handleChange={handleChange}
        />
      ) : (
        <OptionsModal
          className="modal-marketing-form--modal options-modal"
          open={optionsVisible || showOptions}
          onClose={prevClick}
          handleClose={prevClick}
          transitionDuration={0}
          closeAfterTransition
        >
          <FormContainer className="dynamic-form--container">
            <Options
              ref={container}
              visible={optionsVisible || showOptions}
              options={options}
              dataCy={dataCy}
              prevClick={prevClick}
              label={label}
              id={id}
              subtitle={subtitle}
              footerText={footerText}
              handleChange={handleChange}
            />
          </FormContainer>
        </OptionsModal>
      )}
    </>
  );
};

export default SelectEnhanced;
