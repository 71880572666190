import React, { useState, useRef, useEffect } from 'react';
import Modal from 'components/Core/Modal';
import HyperLink from 'components/Core/HyperLink';
import { STLogo } from 'components/Brand/Logos/STLogo';
import Button from 'components/Core/Button';
import { STIcon } from 'components/Brand/Icons/STIcon';
import DynamicMarketoForm from 'components/Form/DynamicMarketoForm';
import { FormRedirect, getFormRedirectValues } from 'components/Form/FormRedirect';
import useNormalDQFlow from 'hooks/useNormalDQFlow';
import useIndustries from 'hooks/useIndustries';
import useBreakpointView from 'utils/useBreakpointView';
import { PricingFormContainer, FullModalContainer } from './styles';
import OtherIndustry from './OtherIndustry';
import './modal-responsive.less';

const PricingForm = ({
  dataPosition,
  formType = 'Swifty Form',
  shadow,
  isModalForm,
  customIcp,
  ...otherProps
}) => {
  const DQFlow = customIcp || useNormalDQFlow();
  const isMobile = useBreakpointView(['xs']);
  const [modalOpen, setModalOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [currentStep, setCurrentStep] = useState(1);
  const [showOtherIndustry, setShowOtherIndustry] = useState(false);
  const [step1Values, setStep1Values] = useState({});
  const passedValues = useRef({});
  const otherIndustry = useIndustries()?.otherIndustryList.sort((a, b) =>
    a.label.localeCompare(b.label),
  );

  const onSuccess = ({ setValues, values }) => {
    const formRedirectData = {
      techs: values.No_of_Technicians__c,
      officeStaff: values?.fullTimeOffice,
      areaOfFocus:
        values.businessFocusResorCommFORMHELPER && values.businessFocusNewConRemodelSRFORMHELPER
          ? values.businessFocusResorCommFORMHELPER
          : 'Residential',
      industry: values.Primary_Industry__c,
      otherIndustry: values.industryTestVerticals,
      typeOfBusiness:
        values.businessFocusResorCommFORMHELPER && values.businessFocusNewConRemodelSRFORMHELPER
          ? values.businessFocusNewConRemodelSRFORMHELPER
          : 'Service & Replacement',
      customDQ: otherProps?.customDQ,
    };

    FormRedirect(DQFlow, formRedirectData);

    return true;
  };

  const onSetValues = (type, values, formStep) => {
    if (
      formStep === 0 &&
      values.Primary_Industry__c === 'Other' &&
      !values.industryTestVerticals?.length
    ) {
      setShowOtherIndustry(true);
    }
    if (
      type === 1 &&
      ((values.Primary_Industry__c?.length > 0 && values.Primary_Industry__c !== 'Other') ||
        (values.Primary_Industry__c === 'Other' && values.industryTestVerticals?.length > 0))
    ) {
      setModalOpen(true);
      setStep1Values({
        ...values,
        Primary_Industry__c: values.Primary_Industry__c,
        industryTestVerticals: values.industryTestVerticals,
      });
      setShowOtherIndustry(false);
    }
  };

  const stepChange = (type, values, formStep) => {
    setCurrentStep(formStep + 1);
  };

  useEffect(() => {
    const container = document.querySelector('#___gatsby');
    if (isMobile && modalOpen) {
      passedValues.current = { ...step1Values };

      if (container) {
        const scrollPos = window.pageYOffset;
        container.style.top = `-${scrollPos}px`;
        container.style.position = 'fixed';
        container.style.width = '100%';
        setScrollPosition(scrollPos);
      }
    }

    return () => {
      container.removeAttribute('style');
    };
  }, [modalOpen]);

  const onOtherIndustrySelected = (id, value) => {
    passedValues.current = {
      industryTestVerticals: value,
      gotoNext: true,
    };
    setShowOtherIndustry(false);
  };

  const onOtherIndustryBack = () => {
    passedValues.current = {
      Primary_Industry__c: '',
      industryTestVerticals: '',
    };
    setShowOtherIndustry(false);
  };

  const handleCloseModal = () => {
    if (isModalForm) {
      otherProps.closeModal();
    } else {
      const container = document.querySelector('#___gatsby');
      container?.removeAttribute('style');
      window.scrollTo(0, scrollPosition);

      setModalOpen(false);
      setCurrentStep(1);
    }
    setShowOtherIndustry(false);
    passedValues.current = {
      Primary_Industry__c: '',
      industryTestVerticals: '',
    };
  };

  const formSubmittedDataLayerProps = (formValues) => ({
    leadStatus: FormRedirect(DQFlow, getFormRedirectValues(formValues), 'leadStatus')
      ? 'MQL'
      : 'MGL',
  });

  const CustomProgress = ({ step }) => (
    <div className={`signup-form--progress progress-step-${step}`}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );

  const renderPricingForm = (type = 0) => {
    return (
      <>
        <PricingFormContainer
          className={`pricing-form--container pricing-form--step-${currentStep}`}
          currentStep={type === 1 ? 0 : currentStep}
          isModalForm={isModalForm}
        >
          <DynamicMarketoForm
            {...otherProps}
            clearOnBack
            backgroundColor="var(--white)"
            titleColor="var(--darkest)"
            dataPosition={dataPosition}
            formType={formType}
            style={{
              boxShadow: shadow ? '0px 30px 60px rgba(11, 26, 33, 0.15)' : 'none',
            }}
            NextLabel="Agree & Get Started"
            onSetValues={(...params) => onSetValues(type, ...params)}
            onSuccess={onSuccess}
            stepChange={(...params) => stepChange(type, ...params)}
            useDataCy
            autoAdvance={currentStep < 4}
            title={['', '', '', otherProps.title]}
            passedValues={passedValues}
            hideProgressBar
            stepPolicy={type === 1 ? { stopStep: 0 } : type === 2 ? { startStep: 1 } : {}}
            useCookieValues={['FirstName', 'LastName', 'Company', 'Email', 'Phone']}
            submitStep={2}
            formSubmittedDataLayerProps={formSubmittedDataLayerProps}
            hideCloseIcon
          />
          <OtherIndustry
            className={`${isModalForm ? 'modal-' : ''}other-industry`}
            visible={showOtherIndustry}
            dropdownList={otherIndustry}
            onSelect={onOtherIndustrySelected}
            onBack={onOtherIndustryBack}
            isModalForm={isModalForm}
          />
          {isMobile !== null && (
            <>
              {!showOtherIndustry && type !== 1 && <CustomProgress step={currentStep} />}
              {type !== 1 && (
                <button type="button" onClick={() => handleCloseModal()} className="modal-close-x">
                  <STIcon type="icon-close-x" />
                </button>
              )}
            </>
          )}
        </PricingFormContainer>
      </>
    );
  };

  if (isMobile && !isModalForm) {
    return (
      <>
        {renderPricingForm(1)}
        <Modal
          className="modal-marketing-form--modal pricing-form--modal full-screen-modal"
          open={modalOpen}
          onClose={() => handleCloseModal()}
          handleClose={() => handleCloseModal()}
        >
          {renderPricingForm(2)}
        </Modal>
      </>
    );
  }

  if (isModalForm) {
    return (
      <FullModalContainer>
        <div className="full-modal-header--wrapper">
          <div className="full-modal-header--logo">
            <HyperLink href="/">
              <STLogo variation="black" />
            </HyperLink>
          </div>
          <Button
            width="84px"
            height="48px"
            type="Transparent - Black Border"
            size="small"
            padding="10px 16px"
            shadow={false}
            onClick={otherProps.closeModal}
          >
            EXIT
          </Button>
        </div>
        {renderPricingForm()}
      </FullModalContainer>
    );
  }

  return renderPricingForm();
};

PricingForm.defaultProps = {
  background: 'var(--white)',
  titleColor: 'var(--darkest)',
  title: 'Let’s get started.',
  buttonLabel: 'Agree and Get Started',
  footer: '',
  shadow: true,
  width: '100%',
};

export default PricingForm;
