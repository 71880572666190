import Cookies from 'js-cookie';
import { flattenRichText } from 'utils/richTextUtils';

// Map of Marketo ID's to expected Data Layer ID's
const fieldMap = {
  utmcampaign__c: 'utm_campaign',
  uTMCampaigncookie: 'utm_campaign',
  UTM_Content__c: 'utm_content_c',
  uTMContentcookie: 'utm_content',
  UTM_Medium__c: 'utm_medium',
  uTMMediumcookie: 'utm_medium',
  UTM_Source__c: 'utm_source',
  uTMSourcecookie: 'utm_source',
  UTM_Term__c: 'utm_term',
  uTMTermcookie: 'utm_term',
  uTMPromo: 'utm_promo',
  uTMPromocookie: 'utm_promo',
  microsoftClickID: 'microsoftClickID',
  microsoftClickIDcookie: 'microsoftClickID',
  Microsoft_Click_ID__c: 'microsoftClickID',
  GCLID__c: 'gclid',
  googleClickIDcookie: 'google_click_cookie',
  Creative__c: 'creative',
  creativecookie: 'creative_cookie',
  mKeyword__c: 'keyword',
  keywordCookie: 'keyword',
  Match_Type__c: 'matchtype_c',
  matchTypecookie: 'matchtype',
  LeadSource: 'lead_source',
  Affiliate_Click_ID__c: 'affiliate_click_id',
  Affiliate_ID__c: 'affiliate_id',
  Affiliate_Order_ID__c: 'affiliate_order_id',
  Email: 'email',
  FirstName: 'first_name',
  LastName: 'last_name',
  Phone: 'phone_number',
  Company: 'company',
  Estimated_Total_Potential_Office__c: 'no_of_office_staff',
  officeStaffNumber: 'no_of_office_staff',
  fullTimeOffice: 'no_of_office_staff',
  No_of_Technicians__c: 'no_of_technicians',
  techniciansNumber: 'no_of_technicians',
  Industry__c: 'industry',
  businessFocusNewConRemodelSRFORMHELPER: 'type_of_business',
  typeOfBusiness: 'type_of_business',
  businessFocusResorCommFORMHELPER: 'area_of_focus',
  areaOfFocus: 'area_of_focus',
  industryTestVerticals: ['other_industry', 'industry'],
  otherIndustry: 'other_industry',
  BusinessFocus__c: 'business_focus',
  iamanexistingServiceTitancustomer: 'existing_ST_user',
  BillingState: 'state',
  Primary_Industry__c: 'industry',
  smsOptIn: 'sms_opt_in',
  clickId: 'click_id',
  Facebook_fbclid__c: 'fbclid',
  iamaGAFcertifiedcontractor: 'iamaGAFcertifiedcontractor',
};

export const mapFormFieldValue = (field) =>
  Array.isArray(fieldMap[field]) ? fieldMap[field][0] : fieldMap[field];

export const mapFormFieldValues = (values) => {
  let mappedFields = {};

  if (values instanceof Object) {
    Object.keys(values)
      .filter((field) => mapFormFieldValue(field) !== undefined)
      .forEach((field) => {
        mappedFields = {
          ...mappedFields,
          ...(Array.isArray(fieldMap[field])
            ? Object.assign({}, ...fieldMap[field].map((v) => ({ [v]: values[field] })))
            : { [fieldMap[field]]: values[field] }),
        };
      });
  }
  return mappedFields;
};

export const getMarketoFormId = (marketoForm) =>
  marketoForm?.id ||
  (marketoForm?.url || marketoForm?.['en-US']?.url)?.split('#')[1].substring(2, 6) ||
  0;

export const getStoredMarketoData = (marketoForm, userInfo) =>
  marketoForm.fields.reduce((accum, field) => {
    const ret = { ...accum };
    const id = field.id.charAt(0).toLowerCase() + field.id.slice(1);
    const o = Object.keys(fieldMap).find((key) => key === field.id);
    const p = Object.keys(fieldMap).find((key) => mapFormFieldValue(key) === field.id);

    if (userInfo && userInfo[id]) {
      ret[field.id] = userInfo[id];
      return ret;
    }
    if (Cookies.get(id)) {
      ret[field.id] = Cookies.get(id);
      return ret;
    }
    if (o && Cookies.get(mapFormFieldValue(o))) {
      ret[field.id] = Cookies.get(mapFormFieldValue(o));
      return ret;
    }
    if (p && Cookies.get(mapFormFieldValue(p))) {
      ret[field.id] = Cookies.get(mapFormFieldValue(p));
      return ret;
    }
    return ret;
  }, {});

export const getRTInlineMarketingFormProps = (fields) => flattenRichText(fields) || {};
