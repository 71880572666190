/* eslint-disable no-use-before-define */
/* eslint-disable dot-notation */
import Cookies from 'js-cookie';
import { enqueueSnackbar } from 'notistack';
import { mapFormFieldValues } from 'utils/marketo';
import { FormRedirect } from 'components/Form/FormRedirect';
import pollForDefinition from 'utils/pollForDefinition';
import { formSubmittedEvent } from 'services/dataLayerService';
import { isInPath } from 'utils/urlHelper';

const BLOCKED_MESSAGE =
  'You appear to be running an ad blocker which may break things on this website. We recommend you add us to your exclusion list for the best experience.';

const marketoMunchkinId = process.env.GATSBY_MARKETO_MUNCHKIN_ID;

const mapper = {
  marketoFormId: ['formVid', 'formid'],
  utm_campaign: ['Affiliate_Click_ID__c', 'utmcampaign__c', 'uTMCampaigncookie'],
  utm_content: ['UTM_Content__c', 'uTMContentcookie'],
  utm_medium: ['UTM_Medium__c', 'uTMMediumcookie'],
  utm_source: ['UTM_Source__c', 'uTMSourcecookie'],
  utm_term: ['UTM_Term__c', 'uTMTermcookie'],
  utm_promo: ['uTMPromo', 'uTMPromocookie'],
  microsoftClickID: [
    'msclkid',
    'microsoftClickID',
    'microsoftClickIDcookie',
    'Microsoft_Click_ID__c',
  ],
  order_id: 'Affiliate_Order_ID__c',
  clickId: 'Affiliate_Click_ID__c',
  gclid: ['GCLID__c', 'googleClickIDcookie'],
  creative: ['Creative__c', 'creativecookie'],
  keyword: ['mKeyword__c', 'keywordCookie'],
  matchtype: ['Match_Type__c', 'matchTypecookie'],
  email: ['Email', 'employeeEmail'],
  firstName: 'FirstName',
  lastName: 'LastName',
  company: 'Company',
  officeStaffNumber: 'Estimated_Total_Potential_Office__c',
  techniciansNumber: 'No_of_Technicians__c',
  techs: 'No_of_Technicians__c',
  techCount: 'No_of_Technicians__c',
  industry: 'Primary_Industry__c',
  typeOfBusiness: 'businessFocusNewConRemodelSRFORMHELPER',
  areaOfFocus: 'businessFocusResorCommFORMHELPER',
  otherIndustry: 'industryTestVerticals',
  businessFocus: 'BusinessFocus__c',
  step: 'step',
  revenue: ['averagerevenueperjob', 'revenueBusValuation'],
  calls: 'averagetotalcallsperday',
  leads: 'whatpercentageofcallsareleads',
  estimates: 'whatpercentageofthoseleadsturnintoestimates',
  jobs: 'whatpercentageofthoseestimatesaresoldjobs',
  officeStaff: 'Estimated_Total_Potential_Office__c',
  existingCustomer: 'iamanexistingServiceTitancustomer',
  state: 'BillingState',
  existingUser: 'iamanexistingServiceTitancustomer',
  grossProfit: 'grossProfitBusValuation',
  ebitda: 'eBIDTABusValuation',
  totalIncrease: 'projectedTotalIncreaseBusValuation',
  businessValuation: 'projectedValueBusValuation',
  coverOverheadOnly: 'coverOverheadLaborRateCalc',
  breakEvenRate: 'breakEvenRateLaborRateCalc',
  billableLaborRate: 'billableLaborRateLaborRateCalc',
  roiCalcWithST: 'rOIwithServiceTitanROICalc',
  roiCalcMonthlyRevenue: 'monthlyRevenueIncreaseROICalc',
  roiCalcAnnualRevenue: 'annualRevenueIncreaseROICalc',
  roiCalcResultLink: 'rOICalculatorResultsLink',
  employee: 'employeeName',
  rsCode: 'SaaSq_Referred_By_Code__c',
  msclkid: ['msclkid', 'microsoftClickID', 'microsoftClickIDcookie', 'Microsoft_Click_ID__c'],
  formType: 'formType',
  cid: 'cID',
};

export const saveForm = async (data, onSuccess) => {
  const formData = new FormData();
  formData.append('_mktoReferrer', window.location.href);
  formData.append('convertingPage', window.location.href);
  formData.append('munchkinId', marketoMunchkinId);
  Object.keys(data)
    .filter(
      (field) =>
        data[field] !== undefined &&
        data[field] !== null &&
        !['null', 'undefined'].includes(data[field]),
    )
    .forEach((field) => {
      const props =
        mapper[field] && Array.isArray(mapper[field])
          ? [...mapper[field]]
          : [mapper[field] || field];
      props.forEach((x) => {
        formData.append(x, data[field]);
      });
    });

  const myForm = MktoForms2.getForm(data.marketoFormId);
  if (myForm) {
    return new Promise((resolve) => {
      const formEle = myForm.getFormElem()[0];

      while (formEle.firstChild) {
        formEle.removeChild(formEle.firstChild);
      }
      myForm.addHiddenFields(Object.fromEntries(formData));
      myForm.onSuccess((values, followUpUrl) => {
        if (isInPath(document.location.href, followUpUrl)) {
          followUpUrl = null;
        }
        resolve({ data: { followUpUrl, values } });
        return false;
      });
      myForm.submit();
    });
  }
  return null;
};

const initMunchkin = () => {
  function initMunchkinLoad() {
    Munchkin.init(marketoMunchkinId);

    if (Munchkin.createTrackingCookie) {
      Munchkin.createTrackingCookie(true);
    } else {
      enqueueSnackbar(BLOCKED_MESSAGE, {
        variant: 'error',
        anchorOrigin: { horizontal: 'center', vertical: 'top' },
        preventDuplicate: true,
      });
    }
  }

  if (!document.getElementById('munchkin_script')) {
    const munchkinScript = document.createElement('script');
    munchkinScript.id = 'munchkin_script';
    munchkinScript.type = 'text/javascript';
    munchkinScript.src = '//munchkin.marketo.net/munchkin.js';
    munchkinScript.async = true;
    munchkinScript.onload = initMunchkinLoad;
    document.body.appendChild(munchkinScript);
  }
};

export const init = (marketoFormId) => {
  initHiddenForm(marketoFormId);
  initMunchkin();
};

const initHiddenForm = (marketoFormId) => {
  if (!marketoFormId) {
    return;
  }

  const hiddenFormName = `mktoForm_${marketoFormId}`;

  if (document.getElementById(hiddenFormName)?.tagName !== 'FORM') {
    const hiddenForm = document.createElement('form');
    hiddenForm.id = hiddenFormName;
    hiddenForm.style = 'display:none';
    document.body.appendChild(hiddenForm);

    pollForDefinition(window, 'MktoForms2', () => {
      const loadForm = MktoForms2.loadForm.bind(
        MktoForms2,
        '//app-sj26.marketo.com',
        marketoMunchkinId,
        marketoFormId,
      );
      loadForm((form) => {
        if (!form) {
          throw Error(`Marketo Form ${marketoFormId} does not exist or is not approved.`);
        }
        form.onSuccess(() => false);
      });
    });
  }
};

export const initEmbedMarketoForm = (
  marketoFormId,
  setFormSubmitted,
  onSuccess,
  shouldRedirect = true,
  dataPosition,
  formType,
  DQFlow,
) => {
  function initMarketoEmbed() {
    // If there's a hidden form exist, remove it first.
    const hiddenForm = document.getElementById(`mktoForm_${marketoFormId}`);
    if (hiddenForm?.tagName === 'FORM' && hiddenForm.style.display === 'none') {
      hiddenForm.remove();
    }

    const loadForm = MktoForms2.loadForm.bind(
      MktoForms2,
      '//app-sj26.marketo.com',
      process.env.GATSBY_MARKETO_MUNCHKIN_ID,
      marketoFormId,
    );

    const formEls = [].slice.call(document.querySelectorAll(`[data-formId="${marketoFormId}"]`));

    (function loadFormCb() {
      const formEl = formEls.shift();
      if (formEl) {
        formEl.id = `mktoForm_${marketoFormId}`;
      }
      loadForm((form) => {
        if (!form) {
          throw Error(`Marketo Form ${marketoFormId} does not exist or is not approved.`);
        }

        form.setValues({
          FirstName: Cookies.get('firstName') || '',
          LastName: Cookies.get('lastName') || '',
          Company: Cookies.get('company') || '',
          Email: Cookies.get('email') || '',
          Phone: Cookies.get('mobilePhone') || '',
          Primary_Industry__c: Cookies.get('industry') || '',
          industryTestVerticals: Cookies.get('otherIndustry') || '',
          No_of_Technicians__c: Cookies.get('techs') || '',
          Estimated_Total_Potential_Office__c: Cookies.get('officeStaff') || '',
          Role__c: Cookies.get('contactRole') || '',
        });

        if (setFormSubmitted) {
          setFormSubmitted(form.validate());
        }

        form.onSuccess((values) => {
          formSubmittedEvent({
            'data-position': dataPosition,
            form_type: formType || 'Dynamic Marketo Form',
            ...mapFormFieldValues(values),
          });

          Cookies.set('firstName', values.FirstName);
          Cookies.set('lastName', values.LastName);
          Cookies.set('company', values.Company);
          Cookies.set('email', values.Email);
          Cookies.set('mobilePhone', values.Phone);
          Cookies.set('industry', values.Primary_Industry__c);
          Cookies.set('otherIndustry', values.industryTestVerticals);
          Cookies.set('techs', values.No_of_Technicians__c);
          Cookies.set('officeStaff', values.Estimated_Total_Potential_Office__c);
          Cookies.set('contactRole', values.Role__c);

          setFormSubmitted(true);

          if (onSuccess) {
            onSuccess();
            if (!shouldRedirect) {
              return false;
            }
          }

          if (marketoFormId === 3063) {
            const redirectData = {};
            ['techs', 'officeStaff', 'businessFocus', 'industry', 'otherIndustry', 'email'].forEach(
              (item) => {
                redirectData[item] = values[mapper[item]];
              },
            );

            FormRedirect(DQFlow, {
              ...redirectData,
              typeOfBusiness: redirectData.businessFocus,
            });
            return false;
          }

          return true;
        });
        if (formEl) {
          formEl.id = '';
        }
        if (formEls.length) loadFormCb(formEls);
      });
    })(formEls);
  }

  pollForDefinition(window, 'MktoForms2', () => {
    initMarketoEmbed();
  });
};
