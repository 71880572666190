import { useCallback } from 'react';
import { navigate } from 'gatsby';
import driftFastlaneTimeout from './driftFastlaneTimeout';

const useDriftFastlane = (driftFastlaneCampaignId) => {
  const driftCallback = useCallback(
    (data, url) => {
      if (!driftFastlaneCampaignId) return;

      try {
        if (window?.drift && document.getElementById('drift-frame-chat')) {
          window.drift.api.collectFormData(data, {
            campaignId: driftFastlaneCampaignId,
            ...(url && { followupUrl: url }),
          });
          url && driftFastlaneTimeout(url);
        } else {
          throw new Error('Drift fastlane not loaded');
        }
      } catch (err) {
        url && navigate(url);
      }
    },
    [driftFastlaneCampaignId],
  );

  return { driftCallback };
};

export default useDriftFastlane;
