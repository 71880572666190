import React, { useState, useRef } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../styles.less';
import DynamicMarketoForm from 'components/Form/DynamicMarketoForm';
import { FormRedirect, getFormRedirectValues } from 'components/Form/FormRedirect';
import useNormalDQFlow from 'hooks/useNormalDQFlow';
import initDnB from 'services/formEnrichmentService';
import SwiftyFormContainer from './styles';
import { hasZeroOffice } from './tradeClassification/hasZeroOffice';
import { hasOneOffice } from './tradeClassification/hasOneOffice';
import useGlowPulse from '../GlowWorm/useGlowPulse';
import '../GlowWorm/styles.less';

const GAF_REDIRECT = '/thankyoudemo';

const isGaf = ({ Primary_Industry__c, iamaGAFcertifiedcontractor }) =>
  Primary_Industry__c === 'Roofing' && iamaGAFcertifiedcontractor === 'yes';

const SwiftyForm = ({ dataPosition, formType, shadow, ...otherProps }) => {
  const normalDQFlow = useNormalDQFlow();
  const DQFlow = otherProps?.customIcp || normalDQFlow;
  const [dnbState, setDnbState] = useState();
  const container = useRef(null);
  const [classificationValues, setClassificationValues] = useState({});

  useGlowPulse(container);

  const onSuccess = ({ setValues, values }) => {
    const formRedirectData = getFormRedirectValues({ ...values, customDQ: otherProps?.customDQ });
    const redirectUrl = isGaf(values)
      ? GAF_REDIRECT
      : FormRedirect(DQFlow, formRedirectData, 'redirectUrl');

    // fastlane callback
    otherProps.onSuccess({ values, redirectUrl });
    return true;
  };

  const writeToState = (dnbData) => {
    setDnbState({ ...dnbData });
  };

  const onStepChange = (data, step) => {
    if (step === 1) {
      const formId = container?.current?.querySelector('form')?.id;
      initDnB(formId, writeToState);

      const {
        No_of_Technicians__c: techs,
        fullTimeOffice: office,
        Primary_Industry__c: industry,
        industryTestVerticals: otherIndustry,
        businessFocusResorCommFORMHELPER: focus,
        businessFocusNewConRemodelSRFORMHELPER: business,
      } = data || {};

      const newPrimaryIndustry = industry === 'Other' ? otherIndustry : industry;

      const tradeClassification =
        office == 0
          ? JSON.parse(hasZeroOffice)?.[newPrimaryIndustry]?.[focus]?.[techs]
          : JSON.parse(hasOneOffice)?.[newPrimaryIndustry]?.[focus]?.[techs];

      setClassificationValues({
        ...tradeClassification,
        Primary_Industry__c: newPrimaryIndustry,
        BusinessFocus__c: `${focus} ${business}`,
      });
    }
  };

  const formSubmittedDataLayerProps = (formValues) => ({
    leadStatus:
      isGaf(formValues) || FormRedirect(DQFlow, getFormRedirectValues(formValues), 'leadStatus')
        ? 'MQL'
        : 'MGL',
  });

  return (
    <SwiftyFormContainer
      ref={container}
      data-cy="swiftyFormContainer"
      className="swiftyFormContainer"
    >
      <DynamicMarketoForm
        {...otherProps}
        backgroundColor="var(--white)"
        titleColor="var(--darkest)"
        dataPosition={dataPosition}
        formType={formType}
        style={{ boxShadow: shadow ? '0px 30px 60px rgba(11, 26, 33, 0.15)' : 'none' }}
        NextLabel="Agree & Continue"
        onSuccess={onSuccess}
        shouldRedirect={false}
        useDataCy
        title={otherProps.title}
        useEnhancedSelect
        passedValues={{ current: { ...dnbState, ...classificationValues } }}
        stepChange={onStepChange}
        formSubmittedDataLayerProps={formSubmittedDataLayerProps}
        disableButtonUntilValid
      />
    </SwiftyFormContainer>
  );
};

SwiftyForm.defaultProps = {
  background: 'var(--white)',
  titleColor: 'var(--darkest)',
  title: 'Let’s get started.',
  buttonLabel: 'Agree and Get Started',
  footer: '',
  shadow: true,
  width: '100%',
};

export default SwiftyForm;
